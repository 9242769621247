<template>
  <div class="applica">
    <!-- 卡片A区 -->
    <!-- <el-card class="box-card one-card" shadow="never">
      <el-form inline :model="form" class="selectform">
        <el-form-item label="角色名称">
          <el-input v-model="form.roleName" placeholder="角色名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="form.iphone" placeholder="联系电话" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="select()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>-->
    <!-- 卡片B区 -->
    <el-card class="box-card two-card" shadow="never">
      <!-- 按钮区域 -->
      <!-- <el-row class="btns">
        <el-button type="primary" @click="addDialogFormVisible = true">添加</el-button>
        <el-button type="primary" @click="edit()">修改</el-button>
      <el-button type="primary" @click="removeBtn()">删除</el-button>-->
      <!-- <el-button type="primary" @click="importBtn">导出</el-button> -->
      <!-- <el-button type="primary" @click="setBtn()">设置权限</el-button> -->
      <!-- </el-row> -->
      <el-row class="tab">
        <!-- 表格区域 -->
        <el-table
          ref="userTable"
          :data="appList"
          tooltip-effect="dark"
          highlight-current-row
          @current-change="handleCurrentChange"
          stripe
          border
          :header-cell-style="rowClass"
        >
          <el-table-column prop="uuid" label="编号" align="center"></el-table-column>
          <el-table-column prop="name" label="应用名称" align="center"></el-table-column>
          <el-table-column prop="code" label="应用编码" align="center"></el-table-column>
          <el-table-column prop="status" label="是否默认" align="center">
            <template slot-scope="scope">
              <span
                :type="scope.row.status===1?'success':'info'"
                effect="dark"
              >{{scope.row.status===1?'默认':'非默认'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="应用备注" align="center"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
        </el-table>
      </el-row>
      <!-- 添加应用弹出框 -->
      <el-dialog :visible.sync="addDialogFormVisible" class="add-dialog" @close="addDialogClose()">
        <span slot="title" class="dialog-Title">添加应用</span>
        <el-form :model="addForm" inline ref="addForm" :rules="addFormRules">
          <el-row>
            <el-form-item label="应用名称" prop="name">
              <el-input v-model="addForm.name" autocomplete="off"></el-input>
            </el-form-item>

            <el-form-item label="应用备注">
              <el-input v-model="addForm.remark" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="是否默认" prop="status">
              <el-select v-model="addForm.status" placeholder="请选择状态" filterable>
                <el-option label="默认" :value="1"></el-option>
                <el-option label="非默认" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="info" @click="addDialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addUser()">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>

export default {
  name: 'applica',
  components: {
  },
  data () {
    return {
      form: {
        roleName: '',
        iphone: ''
      }, // 搜索表单
      addDialogFormVisible: false,
      appList: [], // 应用列表
      selectForm: {}, // 选中项对象
      addForm: {

      }, // 添加应用表单
      addFormRules: {
        name: [
          { required: true, message: '请输入应用名称', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择状态', trigger: 'blur' }
        ]
      }// 添加表单的校验规则
    }
  },
  created () {
    this.getAllApplecation()
  },
  methods: {
    //   获取所有应用列表
    async getAllApplecation () {
      const { data: res } = await this.$http.post('/userManageServer/user/selectApplicationList', {})
      if (res.statusCode !== '200') return this.$message.error('获取订单列表失败')
      this.appList = res.data
      console.log(res)
    },
    // 点击修改按钮触发
    edit () {

    },
    // 点击删除按钮触发
    removeBtn () {

    },
    // 点击设置权限按钮触发
    setBtn () {

    },
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#ccc'
    },
    // 切换选中表格项时触发
    handleCurrentChange (val) {
      // if (!val) return
      //   console.log(val)
    },
    // 切换每页显示数量时触发
    async handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      // await this.getUserList()
    },
    // 切换页码时触发
    async handlePageNumChange (PageNum) {
      this.paging.PageNum = PageNum
      // await this.getUserList()
    },
    addUser () {
      // 预校验
      this.$refs.addForm.validate(async valid => {
        if (!valid) return
        //     // 调接口，发送添加客户请求
        const { data: res } = await this.$http.post('/userManageServer/user/addUser', JSON.parse(JSON.stringify(this.addForm)))
        console.log(res)
        //     // 判断请求是否成功
        //     if (data.statusCode !== '200') return this.$message.error('添加客户失败')
        //     // 成功提示
        //     this.$message.success('添加用户成功')
        //     // 重新渲染数据
        //     this.getUserList()
        //     // 关闭弹框
        //     this.addDialogFormVisible = false
      })
    },
    // 监听添加应用对话框的关闭事件
    addDialogClose () {
      this.$refs.addForm.resetFields()
    }
  }
}
</script>
<style lang="less" scoped>
.applica {
  width: 100%;
  height: 100%;
  .selectform {
    margin: 20px 0;
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .two-card {
    margin-top: 20px;
    .btns {
      margin-bottom: 15px;
    }
    .tab {
      /deep/ .el-table {
        margin-bottom: 20px;
        .el-table__row--striped td {
          background-color: #e4eaec !important;
        }
        .current-row > td {
          background-color: #66b1ff !important;
        }
      }
      .el-pagination {
        margin-bottom: 0.25rem;
        margin-right: 0.25rem;
        text-align: right;
      }
    }
  }
}
</style>
